import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
	base: 'VueRouter',
	routes: [
		{
			path: '/',
			name: 'index',
			component: (resolve) => require(['@/pages/index.vue'], resolve),
			meta: {
				title: '地图标点'
			}
		},
		{
			path: '/login',
			name: 'login',
			component: (resolve) => require(['@/pages/login.vue'], resolve),
			meta: {
				title: '登录'
			}
		},
		{
			path: '/register',
			name: 'register',
			component: (resolve) => require(['@/pages/register.vue'], resolve),
			meta: {
				title: '注册'
			}
		}
	]
})

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
}
export default router;
