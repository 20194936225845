import Vue from 'vue'
import App from './App.vue'
import router from './router'
import httpServer from './client/service/httpServer'
import store from 'storejs'
import api from './api/api.js'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.config.productionTip = false
Vue.prototype.$axios = httpServer
Vue.prototype.$store = store
Vue.prototype.$api = api
Vue.use(MintUI)
new Vue({
	router,
	render: h => h(App),
}).$mount('#app')