import axios from 'axios'
import $config from '@/client/config/index';
import QS from 'qs'
import store from 'storejs'
import router from '@/router'


// 线上环境配置axios.defaults.baseURL，生产环境则用proxy代理
if (process.env.VUE_APP_ENV_NAME !== 'development') {
	axios.defaults.baseURL = $config.baseURL;
}
axios.defaults.headers['Content-Type'] = 'application/json;charse=UTF-8';
axios.defaults.timeout = 30000; // 超时时间 

//请求拦截器
axios.interceptors.request.use(config => {
	config.headers.Authorization = 'Bearer ' + store.get('authorization');
	return config;
}, error => {
	return Promise.reject(error);
});
//响应拦截器即异常处理
axios.interceptors.response.use(response => { 
	if (response.status == 200 || response.data.status == 200) {
		if(!store.get('authorization') && response.headers.authorization){
			store.set('authorization', response.headers.authorization); 
			//如果没有authorization，后台返回了,则添加进状态管理器
		}
		return Promise.resolve(response.data)
	} else {
		// Notify({
		// 	type: 'danger',
		// 	message: response.data.message || response.data.msg || response.data.errMsg
		// });
		if(response.status == 202 || response.data.status == 202){
			setTimeout(t =>{
				router.push('/login');
			},1500);
		}else if(response.data.message === '登录已失效'){
			store.remove('authorization');
			setTimeout(t =>{
				router.push('/login');
			},1500);
		}
		return Promise.reject(response)
	}
}, err => {
	if (err && err.response) {
		switch (err.response.status) {
			case 400:
				err.message = '错误请求';
				break;
			case 401:
				err.message = '未授权，请重新登录';
				router.push('/login');
				break;
			case 403:
				err.message = '没有访问权限，拒绝访问';
				break;
			case 404:
				err.message = '请求错误,未找到该资源';
				break;
			case 405:
				err.message = '请求方法未允许';
				break;
			case 408:
				err.message = '请求超时';
				break;
			case 500:
				err.message = err.response.data.message;
				break;
			case 501:
				err.message = '网络未实现';
				break;
			case 502:
				err.message = '网络错误';
				break;
			case 503:
				err.message = '服务不可用';
				break;
			case 504:
				err.message = '网络超时';
				break;
			default:
				err.message = `连接错误${err.response.msg}`
		}
	} else {
		err.message = "连接到服务器失败"
	}
	// Notify({
	// 	type: 'danger',
	// 	message: err.message || err.response.msg,
	// 	duration: 2000
	// });
	if(err.response && err.response.data.message == '登陆已过期'){
		store.remove('authorization');
		setTimeout(t =>{
			router.push('/login');
		},2000);
	}
	return Promise.reject(err.response)
});
/**
 * 下载文件
 */
let downloadFile = (url) => {
	window.open(url)
};
export default {
	//get请求
	get(url, param, responseType, header) {
		return axios({
			method: 'get',
			url,
			headers: {
				...(header || {})
			},
			responseType: responseType,
			params: param || {},
		})
	},
	//post请求
	post(url, param, header) {
		return axios({
			method: 'post',
			url,
			headers: {
				...(header || {}),
				'Content-Type': 'application/json;charset=UTF-8'
			},
			data: JSON.stringify(param) || {},
		})
	},
	postFormData(url, params, header) {
		return axios({
			method: 'post',
			url,
			headers: {
				...(header || {}),
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			data: QS.stringify(params) || {},
		})
	},
	//post请求
	put(url, param, header) {
		return axios({
			method: 'put',
			url,
			headers: {
				...(header || {}),
				'Content-Type': 'application/json;charse=UTF-8'
			},
			data: param || {},
		})
	},
	// delete
	delete(url, param, header) {
		return axios({
			method: 'delete',
			url,
			headers: {
				...(header || {})
			},
			params: param || {},
		})
	},
	// 此方法非promise 导出文件
	getFile(url, params) {
		let tempParams = {
			...(params || {})
		}
		// 拼接下载地址
		let list = [];
		for (let key in tempParams) {
			list.push(key.toString() + '=' + tempParams[key]);
		}
		url = $config.baseURL + url + '?' + list.join('&');
		url = encodeURI(url);
		downloadFile(url);
	},
	setFile(url, param, header) {
		return axios({
			method: 'post',
			url,
			headers: {
				...(header || {}),
				'Content-Type': 'multipart/form-data'
			},
			data: param || {},
		})
	},
}
