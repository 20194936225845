
// const baseUrl = 'https://go.csdu.net'
export default {
	mapAreaList: '/map/map_area/list', // 查询区域
	mapAreaAdd: '/map/map_area/add', // 新增区域
	mapList: '/map/point/list', // 打点列表
	mapAdd: '/map/point/add', // 新增打点
	importFile: '/map/upload/oss', // 导入
	basicOcr: '/map/ocr/basic', // ocr识别
	login: '/map/members/login', // 登录
	register: '/map/members/register', // 注册
	importExcelFile: '/map/import/excel', // excel导入
	mapAreaDel: '/map/map_area/del', // 区域删除
	pointDel: '/map/point/del', //点删除
}